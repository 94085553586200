<template>
    <div>
        <h1>Navieras</h1>
        <section class="header-navieras">
            <p>Somos la operadora de cruceros lider en México con 28 años de experiencia. <br>
                Representamos a las más importantes
                <b>Navieras Marítimas y Fluviales</b>
                <br> reconocidas a nivel mundial con una oferta de cientos de miles de itinerarios al año.
            </p>
        </section>
        <galleryNavieras />
    </div>
</template>

<script>
import galleryNavieras from '@/components/galleryNavieras.vue'

    export default{
        name: 'NavierasView',
        components: {
            galleryNavieras
        }
    }
</script>

<style scoped>
    .header-navieras{
        padding: 10px 5%;
        height: 300px;
        background: url("../assets/img/header-navieras.jpeg") no-repeat scroll;
        background-size: 100%;
        background-position: 30%;
        color: #fcff45;
    }
    .header-navieras p{
        display: block;
        width: 80%;
        text-align: left;
        font-size: 18pt;
        padding: 15px;
        text-shadow: 2px 2px 2px #3d3d3d;
    }

    @media screen and (max-width: 1100px) {
        .header-navieras{
            padding: 5%;
            height: 200px;
            background-size: cover;
        }
        .header-navieras p{
            width: 90%;
            text-align: left;
            font-size: 16pt;
            padding: 0px;
            margin: 0px;
        }
    }

    @media screen and (max-width: 720px) {
        .header-navieras{
            height: auto;
            padding: 30px 20px;
        }
        .header-navieras p{
            width: 100%;
            text-align: center;
            font-size: 14pt;
            text-shadow: 2px 2px 2px #000;
        }
    }
</style>