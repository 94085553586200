<template>
  <div class="secc-main">
    <section class="secc-index uno">
      <video ref="videoUno" width="640" controls>
        <source type="video/mp4"/>
      </video>

      <div class="textos">
        <h2>{{msg}}</h2>
        <p>agente general exclusivo para México.</p>
      </div>
    </section>
    <section class="secc-index dos">
      <h2>REPRESENTAMOS</h2>
      <p>a las más importantes <b>Navieras Marítimas y Fluviales</b><br>reconocidas a nivel mundial.</p>
    </section>
    <div class="fondo-secciones">
      <section class="secc-index tres">
        <div class="textos">
          <h2>OPERADORA LIDER</h2>
          <p>en México con 28 años de experiencia en el mercado.</p>
        </div>
        <carouselImg :slides="slides" :interval="4000" controls indicators/>
      </section>
      <section class="secc-index cuatro">
        <p class="parrafos p1">Caribe ● Mediterráneo ● Alaska ● Sudamérica ● Escandinavia
          <br> Rios en Europa, Asia y Egipto ● Vuelta al Mundo
        </p>
        <p class="parrafos p2">Hoteles ● Traslados ● Excursiones pre y post crucero</p>
        <p class="parrafos p3">Bodas ● Grupos de incentivos ● Convenciones</p>
      </section>
      <section class="secc-index cinco">
        <video ref="videoDos" width="512" controls></video>
        <video ref="videoTres" width="512" controls></video>
      </section>
    </div>
  </div>
</template>

<script>
import carouselImg from '@/components/carouselImg.vue'
import InterService from '@/services/InterService';

export default {
  name: 'seccMain',
  props: {
    msg: String
  },
  components: {
    carouselImg
  },
  data() {
      return {
        slides: [],
        ruta: '',
        rutaCarrusel: '',
        videos: [],
      }
  },
  methods: {
    async obtenerParamVideos(v1, v2, v3){
        const respuesta = await InterService.obtenerParametro('RUTA_VIDEOS')
        this.ruta = respuesta

        v1.src = this.ruta.valor + this.videos[0].archivo
        v2.src = this.ruta.valor + this.videos[1].archivo
        v3.src = this.ruta.valor + this.videos[2].archivo
    },
    async obtenerVideos() {
        const respuesta = await InterService.obtenerVideos()
        this.videos = respuesta
    },
    async obtenerParamCarrusel(){
        const respuesta = await InterService.obtenerParametro('RUTA_CARRUSEL')
        this.rutaCarrusel = respuesta
        for(let img of this.getSlides){
          this.slides.push(this.rutaCarrusel.valor+img.archivo)
        }
    },
    async obtenerSlides() {
        const respuesta = await InterService.getPromosCarrousel()
        this.getSlides = respuesta
    }
  },
  mounted(){
    let v1 = this.$refs.videoUno
    v1.volume = 0.5
    v1.currentTime = 0.2

    let v2 = this.$refs.videoDos
    v2.currentTime = 0.2
    let v3 = this.$refs.videoTres

    this.obtenerVideos()
    this.obtenerParamVideos(v1, v2, v3)
    

    this.obtenerParamCarrusel()
    this.obtenerSlides()
  }
}
</script>

<style scoped>
h2{
  margin: 0;
  font-size: 35pt;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #31a582;
}

  p{
    margin: 0;
    font-size: 20pt;
  }
  .secc-index{
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-evenly;
    text-transform: uppercase;
  }
  .textos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
  }
  .textos h2{
    background-color: #e6d432;
    padding: 8px 10px;
  }
  .textos p{
    margin-top: 10px;
    padding: 10px;
    background-color: #e6d432;
  }
  .textos h2, .textos p{
    width: 450px;
  }
  .uno{
    padding: 4%;
    background: url("../assets/img/fondo2-main-intercruises.jpeg") no-repeat fixed;
    background-size: cover;
  }
  .carousel{
    margin: 20px;
  }
  .dos, .tres{
    padding: 25px;
  }
  .dos{
    color: #fff;
    background-color: #103b53;
    flex-direction: column;
  }
  .fondo-secciones{
    background: url("../assets/img/inicio-fondo.jpg") no-repeat fixed;
    background-position-y: 0%;
    background-size: 100%;
  }

  .cuatro{
    flex-wrap: wrap;
    flex-direction: column;
    padding: 25px;
    background-color: #103b53;
  }
  .cuatro p{
    font-size: 18pt;
    margin: 5px;
  }
  .p1{
    color: #ffa228;
  }
  .p2{
    color: #38e79e;
  }
  .p3{
    color: #dce924;
  }

  .cinco{
    padding: 30px;
    justify-content: space-around;
  }
  video{
    margin: 10px;
  }

  @media screen and (max-width: 1100px){
    h2{
      font-size: 25pt;
    }
    p{
      font-size: 15pt;
    }
    .textos{
      margin: 10px;
    }
    .textos h2, .textos p{
      width: 350px;
      padding: 10px;
    }
    .dos, .tres{
      padding: 20px;
    }
    .cuatro{
      padding: 15px;
    }
    .cuatro p{
      font-size: 14pt;
      margin: 5px;
    }
    video{
      width: 480px;
    }
  }
  @media screen and (max-width: 720px){
    .uno{
      padding: 5%;
    }
    .fondo-secciones{
      background: linear-gradient(70deg, #ffebdf, #cbe7e5); 
    }
    h2{
      font-size: 20pt;
    }
    p{
      font-size: 12pt;
    }
    .textos h2, .textos p{
      width: 250px;
    }
    .cuatro p{
      font-size: 11pt;
    }
    video{
      width: 320px;
    }
  }
</style>
