<template>
    <div class="carousel">
        <div class="carousel-inner">
            <div ref="scriptInterCruises" id="aquotic-searchbox"></div>
            <carouselIndicators
                v-if="indicators"
                :total="slides.length"
                :current-index="currentSlide"
                @switch="switchSlide($event)"
            ></carouselIndicators>
            <carouselItem
                v-for="(slide, index) in slides"
                :slide="slide"
                :key="`item-${index}`"
                :current-slide="currentSlide"
                :index="index"
                :direction="direction"
                @mouseenter="stopSlideTimer"
                @mouseout="starSlideTimer"
            ></carouselItem>
            <carouselControls v-if="controls" @prev="prev" @next="next"></carouselControls>
        </div>
    </div>
</template>
<script>
import carouselItem from "@/components/carouselItem.vue"
import carouselControls from "@/components/carouselControls.vue"
import carouselIndicators from "@/components/carouselIndicators.vue"

    export default{
        name: 'carouselImg',
        props: {
            slides: {
                type: Array,
                required: true
            },
            controls: {
                type: Boolean,
                default: false
            },
            indicators: {
                type: Boolean,
                default: false
            },
            interval: {
                type: Number,
                default: 4000
            }
        },
        components: {
            carouselItem,
            carouselControls,
            carouselIndicators
        },
        data: () =>({
            currentSlide: 0,
            slideInterval: null,
            direction: "right"
        }),
        methods: {
            setCurrentSlide(index){
                this.currentSlide = index;
            },
            prev(step = -1){
                const index = this.currentSlide > 0 ? this.currentSlide + step : this.slides.length - 1;
                this.setCurrentSlide(index);
                this.direction = "left";
                this.starSlideTimer();
            },
            _next(step = 1){
                const index = this.currentSlide < this.slides.length - 1 ? this.currentSlide + step : 0;
                this.setCurrentSlide(index);
                this.direction = "right";
            },
            next(step = 1){
                this._next(step);
                this.starSlideTimer();
            },
            starSlideTimer(){
                this.stopSlideTimer();
                this.slideInterval = setInterval(() => {
                    this._next();
                }, this.interval)
            },
            stopSlideTimer(){
                clearInterval(this.slideInterval);
            },
            switchSlide(index){
                const step = index - this.currentSlide;
                if(step > 0){
                    this.next(step);
                }else{
                    this.prev(step);
                }
            }
        },
        mounted(){
            this.starSlideTimer();

            let scriptInterCruises = document.createElement('script')
            scriptInterCruises.setAttribute('src', 'https://cruceros.buscadordecruceros.com/es-mx/search-box/1380356')
            scriptInterCruises.setAttribute('gapi_ai', 'true')
            document.head.appendChild(scriptInterCruises)
            let divIC = this.$refs.scriptInterCruises;
            divIC.style.width = '380px'
            divIC.style.position = "absolute"
            divIC.style.left = "12%"
            divIC.style.top = "25px"
            divIC.style.zIndex = "1" 
        },
        beforeUnmount(){
            this.stopSlideTimer();
        }
    }
</script>

<style scoped>
    .carousel{
        display: flex;
        justify-content: center;
    }
    .carousel-inner{
        position: relative;
        width: 100vw;
        height: 70vh;
        overflow: hidden;
    }
</style>