<template>
    <div class="boxGalleryNavieras">
        <div v-for="(slider,index) in sliders" :key="index" class="img-navieras" >
            <img @click="openModal(ruta.valor, slider, index)" :src="ruta.valor + slider.archivo" :alt="slider.nombre" :title="slider.nombre"/>
        </div>
    </div>

    <div ref="ventanaModal" class="bg-modal">
        <div class="secc-btn-close-modal">
            <span id="btnCloseModal" @click="cerrarModal()"><font-awesome-icon icon="fa-solid fa-xmark" /></span>
        </div>
        <div ref="boxImgNavieras" class="box-img-navieras"></div>
    </div>
</template>

<script>
import InterService from '@/services/InterService';

export default {
    name: 'galleryNavieras',
    data() {
        return {
            sliders: [],
            ruta: '',
        }
    },
    mounted(){
        this.obtenerSliders()
        this.obtenerParametro()
    },
    methods: {
        async obtenerParametro(){
            const respuesta = await InterService.obtenerParametro('RUTA_NAVIERA')
            this.ruta = respuesta
        },
        async obtenerSliders() {
            const respuesta = await InterService.obtenerNav()
            this.sliders = respuesta
        },
        openModal(ruta, slider){
            this.$refs.ventanaModal.style.display = "block";
            document.body.style.overflowY = "hidden";

            let newImg = document.createElement("img");
            newImg.src = ruta + slider.archivo
            newImg.alt = slider.nombre
            newImg.title = slider.nombre
            newImg.id = "imgBoxNavieras"
            this.$refs.boxImgNavieras.appendChild(newImg);

            let btnImg = document.createElement("a");
            btnImg.href = slider.url
            btnImg.target = "_blank"
            btnImg.id = "btnBoxNavieras"
            btnImg.innerHTML = "<button>Visitar</button>"
            this.$refs.boxImgNavieras.appendChild(btnImg);
        },
        cerrarModal(){
            this.$refs.ventanaModal.style.display = "none";
            document.body.style.overflowY = "scroll";

            let imgBoxNavieras = document.getElementById("imgBoxNavieras");
            imgBoxNavieras.remove();
            let btnBoxNavieras = document.getElementById("btnBoxNavieras");
            btnBoxNavieras.remove();
        }
    }
}
</script>

<style>
    #imgBoxNavieras{
        width: 35%;
        margin: 30px;
        box-shadow: 1px 1px 5px #868686;
    }
    @media screen and (max-width: 720px){
        #imgBoxNavieras{
            width: 70%;
            margin: 30px;
            box-shadow: 1px 1px 5px #868686;
        } 
    }
</style>

<style scoped>
    .boxGalleryNavieras{
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        padding: 0% 10%;
    }
    .img-navieras{
        width: 200px;
        height: 200px;
        display: flex;
        align-items: center;
        margin: 20px;
    }
    img{
        width: 100%;
        cursor: pointer;
    }
    .img-navieras img:hover{
        transform: scale(1.05);
        box-shadow: 1px 1px 10px #868686;
    }

    .secc-img{
        width: 300px;
        height: 300px;
    }


    .bg-modal{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(255, 255, 255, .95);
        z-index: 1;
        display: none;
    }
    .secc-btn-close-modal{
        position: relative;
    }
    #btnCloseModal{
        position: absolute;
        right: 0;
        cursor: pointer;
        margin: 30px;
        font-size: 30pt;
    }
    .box-img-navieras{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100vh;
    }
</style>