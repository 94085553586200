<template>
    <button class="carousel-control left" @click="$emit('prev')"><font-awesome-icon icon="fa-solid fa-caret-left" /></button>
    <button class="carousel-control right" @click="$emit('next')"><font-awesome-icon icon="fa-solid fa-caret-right" /></button>
</template>

<script>
    export default{
        emits: ['prev', 'next']
    }
</script>


<style scoped>
    .carousel-control{
        background-color: rgba(22, 22, 22, 0.5);
        border: none;
        padding: 0;
        margin: 10px;
        display: inline-block;
        position: absolute;
        height: 40px;
        min-width: initial;
        width: 40px;
        top: calc(50% - 25px);
        color: #fff;
        cursor: pointer;
        font-size: 15pt;
        border-radius: 50%;
    }
    .carousel-control:hover{
        background-color: rgba(22, 22, 22, 1);
    }
    .left{
        left: 0;
    }
    .right{
        right: 0;
    }
</style>