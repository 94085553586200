<template>
  <router-view v-slot="{ Component, route }">
    <HeaderView />
    <Transition @before-enter="scrollToTop" mode="out-in">
      <div :key="route.name">  
        <component :is="Component"></component>
      </div>
    </Transition>
    <FooterView/>
    <ScrolltoTop/>
 </router-view>
</template>

<script>
  import HeaderView from '@/components/Header.vue'
  import FooterView from '@/components/Footer.vue'
  import ScrolltoTop from '@/components/ScrolltoTop.vue'

  export default{
    name: 'App',
    components: {
      HeaderView,
      FooterView,
      ScrolltoTop
    },
    methods:{
        scrollToTop(){
          window.scrollTo(0, 0);
        }
    }
  }
</script>

<style>
h1{
  font-size: 28pt;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
nav a, footer a{
  text-decoration: none;
}
nav a.router-link-exact-active{
  color: #0066a2;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-thickness: 0.1em;
}
</style>
