<template>
        <div class="navbar-social-network">
            <a href="https://www.facebook.com/IntercruisesMexico" target="_blank" ><font-awesome-icon :icon="['fab', 'square-facebook']" /></a>
            <a href="https://www.instagram.com/inter_cruises" target="_blank"><font-awesome-icon :icon="['fab', 'instagram']" /></a>
            <a href="https://www.x.com/@inter_cruises" target="_blank"><font-awesome-icon :icon="['fab', 'x-twitter']" /></a>
        </div>
    <header>
        <picture class="logo-box" @click="linkTo('home')">
            <source srcset="../assets/logo.png" media="(min-width: 800px)" />
            <img alt="Vue logo" src="../assets/logo800px.webp">
        </picture>

        <div @click="menuHdr()" class="btns-hdr-mobiles" ref="btnMenuHdr">
            <font-awesome-icon :icon="['fas', 'bars']" size="2x" id="btnMenuHdr" />
        </div>
        <nav ref="navbar" data-aux="0">
            <div @click="menuHdr()" class="btns-hdr-mobiles" ref="btnCloseHdr">
                <font-awesome-icon :icon="['fas', 'xmark']" size="2x" id="btnCloseHdr"/>
            </div>
            <router-link @click="menuHdr()" to="/">Inicio</router-link>
            <router-link @click="menuHdr()" to="/nosotros">Nosotros</router-link>
            <router-link @click="menuHdr()" to="/navieras">Navieras</router-link>
            <router-link @click="menuHdr()" to="/destinos">Destinos</router-link>
            <div class="op-menu-hdr">
                <span @click="submenuHdr()" class="op-nav-hdr">Promociones y Brochures<font-awesome-icon :icon="['fas', 'chevron-down']" /></span>
                <div class="list-menu-op-hdr" ref="listMenuOpHdr">
                    <router-link @click="menuHdr()" to="/royal-caribbean">Royal Caribbean</router-link>
                    <router-link @click="menuHdr()" to="/celebrity">Celebrity</router-link>
                    <router-link @click="menuHdr()" to="/uniworld">Uniworld</router-link>
                    <router-link @click="menuHdr()" to="/explora">Explora</router-link>
                    <router-link @click="menuHdr()" to="/princess">Princess</router-link>
                </div>
            </div>
            <router-link @click="menuHdr()" to="/reservaciones">Reservaciones</router-link>
            <router-link @click="menuHdr()" to="/contacto">Contacto</router-link>
        </nav>
    </header>
</template>


<script>
    export default{
        name: 'HeaderView',
        data() {
            return{
                val2: 0
            }
        },
        methods: {
            linkTo(ruta){
                this.$router.push({name: ruta})
            },
            menuHdr(){
                let navbar = this.$refs.navbar
                if(document.body.clientWidth < 800){
                    if(navbar.dataset.aux == 0){
                        navbar.style.display = "flex"
                        document.body.style.overflowY = "hidden"
                        navbar.dataset.aux = 1
                    }else{
                        navbar.style.display = "none"
                        document.body.style.overflowY = "scroll"
                        navbar.dataset.aux = 0
                    }
                }
            },
            submenuHdr(){
                let listMenuOpHdr = this.$refs.listMenuOpHdr
                if(document.body.clientWidth < 800){
                    if(this.val2 != 1){
                        listMenuOpHdr.style.display = "flex"
                        this.val2 = 1
                    }else{
                        listMenuOpHdr.style.display = "none"
                        this.val2 = 0
                    }
                }
            }
        },
        mounted() {
            let navbar = document.querySelector("nav");

            if(document.body.clientWidth < 800){
                if(navbar.dataset.aux == 0){
                    navbar.style.display = "none"
                    document.body.style.overflowY = "scroll"
                }else{
                    navbar.style.display = "flex"
                    document.body.style.overflowY = "hidden"
                }
            }else{
                navbar.dataset.aux = 0
            }

            window.addEventListener("resize", function(){
                if(document.body.clientWidth > 800){
                    navbar.style.display = "flex";
                    navbar.dataset.aux = 0;
                    document.body.style.overflowY = "scroll"
                }else{
                    if(navbar.dataset.aux == 0){
                        navbar.style.display = "none";
                    }else{
                        navbar.style.display = "flex";
                    }
                }
            });
        }
    }
</script>

<style>
    nav{
        display: flex;
    }
    .navbar-social-network{
        display: block;
        text-align: right;
        padding: 5px 20px;
        background-color: #f2f9fa;
    }
    .navbar-social-network a{
        margin: -5px;
    }
    .navbar-social-network svg{
        border-radius: 3px;
        background-color: #4eacd7;
        color: #fff;
        padding: 5px;
        font-size: 10pt;
    }
    .navbar-social-network svg:hover{
        transform: scale(1.1);
    }
</style>

<style scoped>
    header{
        display: flex;
        justify-content: space-between;
        padding: 10px 50px;
        align-items: flex-end;
        border-bottom: 1px solid #f3f3f3;
        font-size: 13pt;
    }
    .logo-box{
        cursor: pointer;
    }
    .logo-box:hover{
        transform: scale(1.05);
    }
    .logo-box img{
        height: 80px;
    }
    nav a, .op-nav-hdr{
        color: #8d8d8d;
    }
    .op-nav-hdr{
        cursor: default;
    }
    nav a:hover, .op-nav-hdr:hover{
        color: #4eacd7;
    }
    a, .op-nav-hdr{
        margin: 5px;
    }
    .btns-hdr-mobiles{
        display: none;
    }
    .op-menu-hdr{
        display: flex;
        position: relative;
    }
    .op-menu-hdr:hover .list-menu-op-hdr{
        visibility: visible;
        opacity: 1;
    }
    .list-menu-op-hdr{
        position: absolute;
        padding: 0;
        top: 32px;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        text-align: left;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s ease 0s;
        z-index: 1;
    }
    .list-menu-op-hdr a{
        padding: 10px;
        margin: 0;
    }
    .list-menu-op-hdr a:hover{
        background-color: #f2f9fa;
    }

    @media screen and (max-width: 1250px) {
        header{
            flex-direction: column;
            padding: 10px 20px;
            align-items: center;
        }
    }
    @media screen and (max-width: 800px){
        header{
            flex-direction: row;
            padding: 10px 20px;
        }
        .logo-box:hover{
            transform: none;
        }
        .logo-box img{
            height: 100px;
        }
        .op-menu-hdr:hover .list-menu-op-hdr{
            visibility: visible;
            opacity: 1;
        }
        nav{
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            flex-direction: column;
            align-items: center;
            background-color: #fff;
            height: 100vh;
            width: 100vw;
            z-index: 99;
            padding: 0;
            overflow-y: scroll;
        }
        nav a, .op-nav-hdr{
            width: 100%;
            padding: 20px 0px;
            border-bottom: 1px solid;
            margin: 0;
        }
        nav a.router-link-exact-active{
            text-decoration: none;
        }
        .btns-hdr-mobiles{
            display: block;
            align-self: flex-end;
            cursor: pointer;
        }
        #btnCloseHdr{
            padding: 10px;
        }
        .op-menu-hdr{
            flex-direction: column;
            width: 100%;
        }
        .list-menu-op-hdr{
            display: none;
            visibility: visible;
            opacity: 1;
            position: initial;
            margin-left: auto; 
            margin-right: auto;
            background-color: initial;
            text-align: center;
            border-radius: 0px;
            box-shadow: none;
            transition: all 0.2s ease 0s;
            margin: 0;
            background-color: #f2f9fa;
        }
        .list-menu-op-hdr a{
            padding: 10px 0px;
        }
    }

    @media screen and (max-height: 300px){
        nav a, .op-nav-hdr{
            padding: 5px 0px;
        }
    }
</style>