<template>
    <footer>
        <div class="foot-a">
            <section class="box-footer-contacto">
                <h2>Contáctanos</h2>
                <p><a href="tel:+525555241646"><font-awesome-icon :icon="['fas', 'phone-flip']"/> 55 5524 1646</a></p>
                <p><a href="tel:+525518791958"><font-awesome-icon :icon="['fab', 'whatsapp']"/> 55 1879 1958</a></p>
                <p><a href="mailto:reservaciones@intercruises.com.mx"><font-awesome-icon :icon="['fas', 'envelope']" /> reservaciones@intercruises.com.mx</a></p>
            </section>
            <section class="box-footer-contacto">
                <h2>Visítanos</h2>
                <div class="box-footer-map">
                    <a href="https://maps.app.goo.gl/FLwrqeMNM6uTf9g3A">
                        <font-awesome-icon :icon="['fas', 'map-location-dot']" />
                        <p>José María Rico 121-306,<br>
                            Colonia Del Valle,<br>
                            Benito Juárez, <br> Ciudad de México</p>
                    </a>
                </div>
            </section>
            <section class="box-footer-contacto">
                <h2>Horarios de Atención</h2>
                <p>
                    <b>Lunes a Viernes: </b><br> 09:00 a 19:00 <br>
                    <b>Sábado: </b> <br>09:00 a 14:00 <br>
                    <b>Domingo: </b> Cerrado <br>
                </p>
            </section>
            <section class="box-footer-contacto">
                <h2>Síguenos</h2>
                <p><a href="https://www.instagram.com/inter_cruises" target="_blank"><font-awesome-icon :icon="['fab', 'instagram']" /> /inter_cruises</a></p>
                <p><a href="https://www.x.com/@inter_cruises" target="_blank"><font-awesome-icon :icon="['fab', 'x-twitter']" /> @inter_cruises</a></p>
                <p><a href="https://www.facebook.com/IntercruisesMexico" target="_blank"><font-awesome-icon :icon="['fab', 'square-facebook']" /> /IntercruisesMexico</a></p>
            </section>
        </div>
        <div class="foot-b">
            <span>Operadora de Viajes S.A. de C.V. <br>Todos los derechos reservados.</span>
            <RouterLink to="/aviso-privacidad">Aviso de Privacidad</RouterLink>
        </div>
    </footer>
</template>


<script>
    export default{
        name: 'FooterView'
    }
</script>

<style scoped>
    footer{
        text-align: start;
        font-size: 11pt;
        font-family: 'Nunito';
    }
    footer, footer a{
        color: #fff;
    }
    footer a:hover{
        text-decoration: underline;
    }
    .foot-a{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        background-color: #0066a2;
        padding: 20px;
    }
    .foot-a svg{
        font-size: 17pt;
    }
    .box-footer-map a{
        display: flex;
        align-items: flex-start;
    }
    .box-footer-map p{
        margin: 0;
        margin-left: 5px;
    }
    .foot-b{
        font-weight: bold;
        background-color: #4eacd7;
        padding: 15px 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media screen and (max-width: 1250px) {
        .box-footer-contacto{
            width: 200px;
        }
    }

    @media screen and (max-width: 900px) {
        footer{
            font-size: 10pt;
            text-align: center;
        }
        .box-footer-contacto h2{
            font-size: 13pt;
        }
        .foot-b{
            padding: 10px 30px;
            font-size: 10pt;
        }
    }
    @media screen and (max-width: 450px) {
        footer{
            text-align: start;
        }
        .foot-a{
            flex-wrap: nowrap;
            flex-direction: column;
            justify-content: space-evenly;
            padding: 0;
        }
        .box-footer-contacto{
            width: auto;
            text-align: center;
        }
        .box-footer-contacto h2{
            font-size: 15pt;
        }
        .box-footer-map a{
            flex-direction: column;
            align-items: center;
        }
        .foot-b{
            padding: 10px 20px;
            font-size: 9pt;
        }
    }
</style>