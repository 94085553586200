<template>

    <div class="carousel-indicators">
        <button class="carousel-indicator-item"
            :class="{ active: currentIndex === index }"
            v-for="(item, index) in total"
            :key="index"
            @click="$emit('switch', index)"
        ></button>
    </div>

</template>

<script>
    export default{
        emits: ['switch'],
        props: ['total', 'currentIndex'],
    }
</script>

<style scoped>
    .carousel-indicators{
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        bottom: 1.5em;
        z-index: 2;
    }
    .carousel-indicator-item{
        min-width: initial;
        margin: 0.2em;
        width: 18px;
        height: 10px;
        background: #000;
        opacity: 0.5;
        cursor: pointer;
    }
    .active{
        background: #fff;
        opacity: 1;
    }
</style>