<template>
    <div @click="scrollToTop()" class="scrolltoTop" ref="btnScrolltoTop">
        <font-awesome-icon :icon="['fas', 'caret-up']" />
    </div>
</template>

<script>
    export default{
        name: 'ScrolltoTop',
        methods:{
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        },
        mounted(){
            let btnScroll = this.$refs.btnScrolltoTop
            window.onscroll = function(){
                if(window.scrollY >= 50){
                    btnScroll.style.visibility = 'visible'
                    btnScroll.style.opacity = '1'
                }else{
                    btnScroll.style.opacity = '0'
                    btnScroll.style.visibility = 'hidden'
                }
            };
        }
    }
</script>

<style scoped>
    .scrolltoTop{
        visibility: hidden;
        position: fixed;
        bottom: 55px;
        right: 30px;
        padding: 5px 15px;
        background-color: #747474;
        color: #fff;
        border-radius: 5px;
        border-radius: 1px solid #fff;
        cursor: pointer;
        transition: all 1s ease 0s;
        font-size: 20pt;
    }
    .scrolltoTop:hover{
        background-color: #2b2b2b;
    }


</style>