import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NavierasView from '../views/Navieras.vue'
import ReservacionesView from '../views/Reservaciones.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Inter Cruises'
    }
  },
  {
    path: '/aviso-privacidad',
    name: 'avisoPrivacidad',
    component: () => import('../views/AvisoPrivacidad.vue'),
    meta: {
      title: 'Aviso de Privacidad'
    }
  },
  {
    path: '/nosotros',
    name: 'nosotros',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue'),
    meta: {
      title: 'Nosotros'
    }
  },
  {
    path: '/navieras',
    name: 'navieras',
    component: NavierasView,
    meta: {
      title: 'Navieras'
    }
  },
  {
    path: '/destinos',
    name: 'destinos',
    component: () => import('../views/Destinos.vue'),
    meta: {
      title: 'Destinos'
    }
  },
  {
    path: '/reservaciones',
    name: 'reservaciones',
    component: ReservacionesView,
    meta: {
      title: 'Reservaciones'
    }
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: () => import('../views/Contacto.vue'),
    meta: {
      title: 'Contáctanos'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('../views/NotFound.vue'),
    meta: {
      title: '404 - Página no encontrada'
    }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  document.title = to.meta?.title ??'Inter Cruises'
})

export default router
