import HttpService from "./HttpService";

const InterService = {
    async obtenerParametro(name){
        return await HttpService.post('/getParametroByName', {
            name: name
        })
    },
    async obtenerNav(){
        return await HttpService.get('/SlidersNav')
    },
    async obtenerVideos(){
        return await HttpService.get('/getIndexVideos')
    },
    async getPromosCarrousel(){
        return await HttpService.get('/getIndexPromos')
    }

    }
export default InterService