import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '../src/assets/css/custom.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas as faSolid } from '@fortawesome/free-solid-svg-icons'
import { far as faRegular } from '@fortawesome/free-regular-svg-icons'
import { fab as faBrands } from '@fortawesome/free-brands-svg-icons'
library.add(faSolid, faRegular, faBrands)

createApp(App).use(router)
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')
