<template>
  <div class="home">
    <div class="fondo-script">
      <slideImg :slides="slides" :interval="4000" controls indicators/>
    </div>
    <seccMain msg="Inter Cruises"/>
  </div>
</template>

<script>
// @ is an alias to /src
import seccMain from '@/components/seccMain.vue'
import slideImg from '@/components/slideImg.vue'

export default {
  name: 'HomeView',
  components: {
    seccMain,
    slideImg
  },
  data: () => ({
      slides: [
          "https://picsum.photos/id/28/1280/512",
          "https://picsum.photos/id/29/1280/512",
          "https://picsum.photos/id/30/1280/512",
          "https://picsum.photos/id/32/1280/512"
      ]
  })
}
</script>

<style scoped>
  .fondo-script{
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-evenly; 
  }
</style>